.amenities-wrapper {
  padding-top: 95px;
}

.amenities-wrapper .banner-footer {
  height: auto;
}

.amenities-wrapper .box {
  max-width: 985px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 2.76px 0.24px rgba(15, 26, 47, 0.05);
  padding: 50px;
  margin: 0 auto 100px;
}

.amenities-wrapper .add-ons-box .box-content {
  display: flex;
}

.amenities-wrapper .add-ons-box .box-content span {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #232b42;
  margin: 0 8px;
}

.amenities-wrapper .box h2 {
  margin: 0 0 25px;
  padding: 0;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #232b42;
}

.amenities-wrapper .add-ons-box .box-content h2 {
  margin-bottom: 0px;
}

.amenities-wrapper .box .text {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 18px;
  line-height: 1.5;
}

.amenities-box .box-content:not(:last-child) {
  margin-bottom: 40px;
}

.add-ons-box .box-content:not(:last-child) {
  margin-bottom: 20px;
}

.amenities-wrapper .bg-image {
  object-position: top;
}

.amenities-wrapper .banner-title {
  margin-bottom: 80px;
}

.amenities-wrapper.bg-image-wrapper .bg-image {
  max-height: 580px;
}

.amenities-wrapper .overlay {
  background: linear-gradient(#ecf1f6 calc(100% - 510px), transparent 100%);
}

@media only screen and (max-width: 1600px) {
  .amenities-wrapper {
    padding-top: 70px;
  }

  .amenities-wrapper.bg-image-wrapper .bg-image {
    max-height: 535px;
  }
}

@media only screen and (max-width: 1200px) {
  .amenities-wrapper.bg-image-wrapper .bg-image {
    max-height: 480px;
  }

  .amenities-wrapper .overlay {
    background: linear-gradient(#ecf1f6 calc(100% - 470px), transparent 100%);
  }
}

@media only screen and (max-width: 768px) {
  .amenities-wrapper.bg-image-wrapper .bg-image {
    max-height: 440px;
  }

  .amenities-wrapper .overlay {
    background: linear-gradient(#ecf1f6 calc(100% - 410px), transparent 100%);
  }
}

@media only screen and (max-width: 576px) {
  .amenities-wrapper .bg-image-content {
    padding-bottom: 70px;
  }

  .amenities-wrapper .banner-title {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 480px) {
    .amenities-wrapper.bg-image-wrapper .bg-image {
        max-height: 480px;
        object-position: 30%;
      }
    
      .amenities-wrapper .overlay {
        background: linear-gradient(#ecf1f6 calc(100% - 470px), transparent 100%);
      }
}

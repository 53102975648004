.banner {
    background-size: cover;
    background-position: center;
    height: 700px;
}

.banner-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80%;
}

.banner-footer {
    height: 340px;
    margin-top: 0px;
}

.banner-footer .banner-content {
    display: flex;
    padding-top: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.banner-title {
    margin-bottom: 42px;
    color: #fff;
}

.banner-footer .banner-title {
    color: #232b42;
}

.hero .banner-title {
    font-size: 56px;
    text-shadow: 0px 2px 14.72px rgba(15, 26, 47, 0.31);
}


@media only screen and (max-width: 1600px) {
    .banner {
        height: 420px;
    }

    .banner-footer {
        height: 280px;
    }

    .hero .banner-title {
        font-size: 46px;
    }
}

@media only screen and (max-width: 768px) {
    .hero .banner-title {
        font-size: 40px;
    }

    .banner input, .banner .find-btn, .rv-msg {
        height: 50px;
        font-size: 15px;
    }

    .banner input {
        max-width: 200px;
        width: 100%;
    }

    .calendar-img {
        height: 16px;
        top: 16px;
        right: 15px
    }

    .banner .find-btn {
        max-width: 150px;
        width: 100%;
    }

    .banner .react-datepicker-wrapper input {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 576px) {
    .banner .find-btn {
        display: flex;
        margin: 15px auto 0px;
    }

    .banner .fields-wrapper {
        display: block;
    }

    .banner .fields-wrapper .date-wrapper {
        display: inline-block;
    }
    
}

@media only screen and (max-width: 576px) {
    .banner .fields-wrapper .date-wrapper {
        display: block;
        margin-bottom: 15px;
        margin-right: 0px;
    }

    .banner .fields-wrapper {
        width: 100%;
        max-width: 350px;
    }

    .banner input, .banner .find-btn, .react-datepicker-wrapper {
        max-width: 100%;
        width: 100%;
    }

    .banner {
        height: 460px;
    }

    .banner-footer {
        height: 340px;
    }

    .banner-content {
        height: 100%;
    }

}
.header-wrapper {
    box-shadow: 0px -5px 13px 0px rgb(0 0 0 / 22%);
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
}

.header {
    display: flex;
    align-items: center;
}

.header-logo {
    margin-right: 50px;
    max-width: 280px;
    width: 100%;
}

.header-logo img {
    width: 100%;
}

.menu-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
}

.menu-overlay .menu-content {
    position: fixed;
    padding: 70px 20px 0px;
    right: 20px;
    z-index: 6;
    top: 0px;
    right: 0px;
    text-align: right;
    min-width: 350px;
    width: 40%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 480px) {
    .menu-overlay .menu-content {
        width: 100%;
    }

    .header-logo {
        max-width: 230px;
    }
}

.menu-overlay .menu-content .navigation {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    line-height: 2;
}

.menu-overlay .menu-content a {
    color: #fff;
    font-size: 20px;
    margin-right: 0;
}

.menu-overlay .navigation a.active {
    font-weight: 700;
}

.menu-overlay .navigation a:not(:last-child) {
    margin-right: 0px;
}

.menu-overlay .navigation a.active::after {
    display: none;
}

.menu-content h2 {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
}

.desk-nav {
    display: flex;
    width: 100%;
    align-items: center;
}

.menu-btn {
    height: 100%;
    display: none;
    align-items: center;
    position: absolute;
    right: 15px;
}

.nav-icon1 {
    width: 45px;
    height: 42px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 7;
  }
  
  .nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #232b42;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .nav-icon1 span:nth-child(1) {
    top: 0px;
  }
  
  .nav-icon1 span:nth-child(2) {
    top: 18px;
  }
  
  .nav-icon1 span:nth-child(3) {
    top: 36px;
  }

  .nav-icon1.open span {
      background-color: #fff;
  }
  
  .nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  .nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

@media only screen and (max-width: 1600px) {

    .header-wrapper {
        padding-top: 25px;
        padding-bottom: 25px;    
    }

    .header-logo {
        margin-right: 60px;
    }
}

@media only screen and (max-width: 992px) {
    .desk-nav {
        display: none;
    }

    .menu-btn {
        display: inline-flex;
    }
}
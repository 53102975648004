.loader {
    margin: 20px auto;
    text-align: center;
}

.payment-form {
    max-width: 600px;
    width: 100%;
}

.user {
    position: relative;
    margin-bottom: 40px;
}

.user .error-msg {
    right: 0px;
    width: 48%;
    margin-left: auto;
}

.user .error-msg.centered {
    width: 100%;
    text-align: center;
    margin: auto;
}

.input-field {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    height: 52px;
    width: 100%;
    padding: 0px;
    color: #232b42;
}

.payment-form .two-col {
    justify-content: space-between;
    display: flex;
    margin: 10px 0px 15px;
}

.two-col .input-field {
    width: 49%;
}

.button-credit-card {
    display: flex;
    border: none;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
}

.button-credit-card:focus {
    outline: none;
}

.button-credit-card:disabled {
    background-color: rgb(87, 87, 87);
    color: rgb(207, 207, 207);
    cursor: default;
    opacity: 1;
}

.payment-wrapper .error-msg {
    bottom: -30px;
}

.google-pay {
    margin-top: 20px;
    text-align: center;
}

.google-pay .gpay-card-info-container {
    width: 100%!important;
}

@media only screen and (max-width: 480px) {
    .payment-form .two-col {
        display: block;
    }
    
    .payment-form .two-col .field-wrapper {
        width: 100%;
    }
    
    .payment-form .two-col .field-wrapper  label {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.bg-image-wrapper {
  min-height: 645px;
  position: relative;
}

.bg-image-wrapper .bg-image {
  object-fit: cover;
  object-position: bottom;
  position: absolute;
  z-index: 0;
  width: 100%;
  max-height: 645px;
  left: 0;
  bottom: 0;
  height: 100%;
}

.overlay {
  background: linear-gradient(#ecf1f6 calc(100% - 645px), transparent 100%);
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.bg-image-content {
  position: relative;
  z-index: 2;
  padding-bottom: 140px;
}

.bg-image-content .three-col-grid .box {
  margin-bottom: 50px;
  display: inline-block;
}

.bg-image-content .three-col-grid-wrapper {
  padding-bottom: 0px;
}

.bg-image-wrapper .rv-msg {
  max-width: 249px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.bg-image-wrapper .three-col-grid {
  display: block;
  columns: 3;
}

@media only screen and (max-width: 1200px) {
  .bg-image-wrapper .three-col-grid {
    columns: 2;
  }
}

@media only screen and (max-width: 768px) {
  .bg-image-wrapper .three-col-grid {
    columns: 1;
  }

  .bg-image-content h1 {
    margin-bottom: 30px;
  }

  .bg-image-wrapper.amenities-wrapper .box .text {
    font-size: 16px;
  }

  .bg-image-wrapper.amenities-wrapper .box h2 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .bg-image-wrapper.amenities-wrapper .box {
    margin-bottom: 55px;
  }

  .bg-image-content .three-col-grid-wrapper {
    padding-bottom: 20px;
  }

  .bg-image-content .banner .fields-wrapper {
    text-align: center;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .bg-image-wrapper .rv-msg {
    max-width: 100%;
    bottom: -70px;
  }
}

.user-payment-info-wrapper .field-wrapper {
    position: relative;
}

.user-payment-info-wrapper .field-wrapper input {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    height: 52px;
    width: 100%;
    padding: 0px 15px;
}

.user-payment-info-wrapper input, .user-payment-info-wrapper textarea {
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 17px;
}

.user-payment-info-wrapper textarea {
    padding: 15px;
}

.user-payment-info-wrapper input:focus, .user-payment-info-wrapper textarea:focus {
    outline: none;
}

.user-payment-info-wrapper .field-wrapper textarea {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    width: 100%;
    height: 115px;
    resize: vertical;
}

.user-payment-info-wrapper .two-col {
    display: flex;
    margin-bottom: 25px;
}

.user-payment-info-wrapper .two-col .field-wrapper {
    width: 48%;
}

.user-payment-info-wrapper .field-wrapper label {
    display: block;
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 1;
}

.error-msg {
    color: #b90000;
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    bottom: -30px;
    left: 0px;
}

.user-payment-info-wrapper .field-wrapper input.error-field {
    border-color: #b90000;
}
@font-face {
    font-family: 'IntroDemo';
    src: local('IntroDemo'), url(./fonts/IntroDemo-BlackCAPS.woff) format('woff');
  }

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.boxed-content {
    max-width: 1200px;
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
}

.blue-btn {
    margin-left: auto;
    background-color: #3286ed;
    color: #fff;
    border-radius: 10px;
    height: 47px;
    padding: 0px 35px; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-decoration: none;
}

.blue-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}


@media only screen and (max-width: 1600px) {
    .blue-btn {
        height: 42px;
        padding: 0px 30px;
        font-size: 15px;
    }
}
.fields-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.date-wrapper {
    position: relative;
    margin-right: 22px;
}

.details .date-wrapper {
    margin-right: 18px;
}

.react-datepicker-wrapper input {
    height: 72px;
    width: 249px;
    background-color: #fff;
    color: #232b42;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    padding-left: 28px;
}

.details .react-datepicker-wrapper input {
    color: #fff;
    background-color: #232b42;
    height: 60px;
    width: 207px;
    font-size: 16.5px;
}

.react-datepicker-wrapper input:focus {
    outline: none;
}

.react-datepicker-wrapper input::placeholder {
    color: #232b42;
}

.details .react-datepicker-wrapper input::placeholder {
    color: #fff;
}

.calendar-img {
    height: 21px;
    width: 25px;
    position: absolute;
    right: 25px;
    top: 26px;
    pointer-events: none;
}

.details .calendar-img {
    height: 17px;
    width: 21px;
}


.find-btn {
    color: #19331d;
    height: 72px;
    width: 174px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border-radius: 10px;
    font-size: 20px;
    background-color: #3ce558;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.find-btn:hover {
    cursor: pointer;
}

.details .find-btn {
    height: 60px;
    width: 122px;
    font-size: 16.5px;
}

.rv-msg {
    position: absolute;
    bottom: -75px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    background-color: #b90000b0;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1600px) { 
    .react-datepicker-wrapper input, .find-btn, .rv-msg {
        height: 55px;
        font-size: 17px;
    }
    
    .calendar-img { 
        top: 18px;
    }
}

@media only screen and (max-width: 480px) {
    .rv-msg {
        bottom: -65px;
    }
}
.box {
    border-radius: 10px;
    width: 360px;
    max-width: 360px;
}

.rv-box {
    height: 269px;
    background-size: cover;
    margin-bottom: 32px;
}

.rv-name {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    margin: 0 0 13px;
    padding: 0;
}

.rv-element {
    max-width: 360px;
}

.rv-element a {
    color: #3286ed;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-decoration: none;
}

.review-box {
    background-color: #fff;
    padding: 45px 35px 95px;
    position: relative;
    box-shadow: 0px 2px 2.76px 0.24px rgba(15, 26, 47, 0.05);
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
    backface-visibility: hidden;
    z-index: 1;
}

.review-box .info {
    position: absolute;
    bottom: 25px;
    font-size: 17px;
    color: #232b42;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
}

.review-box .text {
    font-size: 18px;
    color: #232b42;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    line-height: 1.5;
    margin: 0;
}

.review-box .name {
    font-weight: 700;
    margin-bottom: 5px;
}

.review-box img {
    height: 26px;
    width: 30px;
    position: absolute;
    left: 35px;
    top: -13px;
}

.rv-element .price {
    color: #232b42;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
}


@media only screen and (max-width: 1600px) {
    .review-box .text {
        font-size: 16px;
    }

    .review-box .info {
        font-size: 15px;
    }
}

.rv-element {
    margin-right: 45px;
}


.rv-element:nth-child(3n) {
    margin-right: 0;
}

@media only screen and (max-width: 1200px) {
    .review-box {
        margin: 0 auto 50px;
    }
}


@media only screen and (max-width: 1200px) and (min-width: 769px) {
    .rv-element:nth-child(3n){
        margin-right: 45px;
    }

    .rv-element:nth-child(2n){
        margin-right: 0px;
    }
}

@media only screen and (max-width: 850px) and (min-width: 769px) {
    .box {
        width: 320px;
    }

    .rv-box {
        height: 250px;
    }

    .rv-name {
        font-size: 16px;
        margin: 0 0 4px;
    }

    .rv-element a {
        font-size: 15px;
    }

    .review-box .text {
        font-size: 15.5px;
    }

    .review-box {
        padding-top: 35px;
    }

    .review-box .info {
        bottom: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .box, .rv-element {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
    }

    .review-box {
        height: auto;
        padding: 35px;
    }

    .review-box .info {
        position: static;
        margin-top: 20px;
    }

    .review-box .info .name {
        margin-bottom: 0px;
    }
}
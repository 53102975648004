.gray-section {
    background-color: #ecf1f6;
}

.about-us {
    padding-top: 95px;
    padding-bottom: 95px;
}

.about-us {
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
}

.about-us p {
    line-height: 1.6;
    font-size: 18px;
    font-style: italic;
}

.about-us p:first-of-type {
    margin-top: 0;
}

.about-us span {
    font-weight: 700;
}

.home-wrapper .bg-image-wrapper .bg-image {
    max-height: 755px;
    object-position: top;
}

@media only screen and (max-width: 1600px) {
    .about-us p {
        font-size: 17px;
        line-height: 1.7;
    }
}

@media only screen and (max-width: 1200px) {
    .home-wrapper .banner-footer {
        height: 345px;
    }
}

@media only screen and (max-width: 768px) {
    .about-us p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 576px) {
    .about-us p {
        font-size: 15px;
    }

    .home-wrapper .bg-image-content {
        padding-bottom: 105px;
    }

    .home-wrapper .bg-image-wrapper .bg-image {
        object-position: 62%;
    }
}

@media only screen and (max-width: 480px) {
    .about-us p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1200px) {
    .home-wrapper .three-col-grid {
        max-width: 800px;
        justify-content: flex-start;
        margin-left: auto;
        margin-right: auto;
    }

    .home-wrapper .review-box, .home-wrapper .rv-element {
        margin-bottom: 30px;;
    }
}
.rv-details .left {
  width: 528px;
  min-width: 528px;
}

.rv-details .right {
  margin-left: 70px;
}

.rv-details img {
  border-radius: 10px;
}

.rv-details .images {
  margin-top: 25px;
  margin-bottom: 80px;
}

.rv-details .images .rv-image {
  margin-right: 25px;
}

.rv-image {
  object-fit: cover;
  height: 119px;
  width: 159px;
  margin-bottom: 21px;
}

.rv-image:hover {
  cursor: pointer;
}

.less-than .rv-image:not(:nth-child(3n)) {
  margin-right: 21px;
}

.more-than.images {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.more-than.images .rv-image:nth-child(3n) {
  margin-right: 0px;
}

.rv-details-wrapper {
  padding-top: 94px;
}

.rv-details {
  display: flex;
  justify-content: space-between;
}

.rv-details h1 {
  text-align: left;
  margin-bottom: 23px;
}

.rv-details h3 {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #232b42;
  font-size: 18px;
}

.rv-description li {
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 17px;
  line-height: 1.7;
}

.rv-description li p {
  margin-bottom: 0;
}

.rv-description {
  padding-left: 0px;
  margin: 15px 0px 0px 19px;
}

.rv-description li::marker {
  color: #3ce558;
}

.rv-details .avail-title {
  margin-top: 50px;
  margin-bottom: 35px;
}

.status-msg-wrapper {
  position: relative;
}

.status-msg {
  color: #b90000;
  font-family: "Open Sans";
  font-size: 20px;
  position: absolute;
  bottom: -35px;
  left: 0px;
}

.available {
  font-family: "Open Sans";
  font-size: 20px;
  margin-top: 20px;
  display: flex;
}

@media only screen and (min-width: 1200px) {
  .details .calendar-img {
    top: 22px;
  }
}

@media only screen and (max-width: 1600px) {
  .rv-description li {
    font-size: 15px;
  }

  .rv-details-wrapper {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 1200px) {
  .details .react-datepicker-wrapper input,
  .rv-details .find-btn {
    height: 50px;
    font-size: 15px;
  }

  .details .calendar-img {
    top: 16px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .details .react-datepicker-wrapper input {
    max-width: 207px;
    width: 100%;
    padding-left: 15px;
  }

  .details .calendar-img {
    right: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .rv-details {
    flex-direction: column-reverse;
    max-width: 90%;
    margin: 0 auto;
  }

  .rv-details .fields-wrapper {
    justify-content: flex-start;
  }

  .rv-details .right {
    margin-left: 0;
    margin-bottom: 60px;
  }

  .available {
    font-size: 17px;
  }
}

@media only screen and (max-width: 768px) {
  .rv-details .fields-wrapper {
    flex-direction: column;
    align-items: flex-start;
    max-width: 260px;
    width: 100%;
  }

  .rv-details .fields-wrapper input,
  .rv-details .fields-wrapper .find-btn {
    max-width: 100%;
    width: 100%;
  }

  .rv-details .fields-wrapper .date-wrapper {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  .rv-details .react-datepicker-wrapper {
    width: 100%;
  }

  .available {
    max-width: 245px;
    width: 100%;
    margin: 20px 0 0;
    font-size: 16px;
    height: 60px;
    text-align: center;
  }

  .rv-details .left {
    width: 100%;
    min-width: auto;
  }

  .rv-details .fields-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .available {
    max-width: 100%;
  }

  .more-than.images .rv-image:nth-child(3n) {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 450px) {
  .rv-details .slick-slide img {
    height: 320px;
    min-height: 320px;
  }

  .rv-details .images .rv-image {
    width: 100%;
    margin-right: 0px!important;
  }
}

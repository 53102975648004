.delivery {
    background-size: cover;
    background-position: center;
    height: 400px;
}

.delivery-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80%;
}

.delivery-title {
    color: #232b42;
}

.hero .delivery-title {
    font-size: 56px;
    text-shadow: 0px 2px 14.72px rgba(15, 26, 47, 0.31);
}

.delivery-content input {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    width: 75%;
    height: 52px;
    resize: vertical;
    margin-bottom: 25px;
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 17px;
    padding: 0px 20px;
}

.delivery-content .calc-btn {
    color: #19331d;
    height: 50px;
    width: 225px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border-radius: 10px;
    font-size: 15px;
    background-color: #3ce558;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.delivery-content .calc-btn:hover {
    cursor: pointer;
}

.delivery-content .calc-btn.disabled {
    background-color: rgb(87, 87, 87);
    color: rgb(207, 207, 207);
    cursor: default;
    opacity: 1;
}

.delivery-content h2 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin: 0 0 25px;
    padding: 0;
    color: #232b42;
}
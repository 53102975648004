.slick-slide img{
    height: 384px;
    min-height: 384px;
    width: 100%;
    object-fit: cover;
  }

  .slick-slide img:hover {
      cursor: pointer;
  }

  .slick-slider .slick-prev {
      left: 10px;
      z-index: 5;
  }

  .slick-slider .slick-next {
      right: 10px;
      z-index: 5;
  }

  .slick-next:before, .slick-prev:before {
      color: #fff;
      opacity: 1;
  }
.three-col-grid-wrapper {
    padding: 95px 0px;
}

.three-col-grid {
    display: flex;
    flex-wrap: wrap;
}

.three-col-grid-wrapper.home .three-col-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

h1 {
    text-align: center;
    font-family: 'IntroDemo', sans-serif;
    margin: 0 0 75px;
    padding: 0;
    font-size: 32px;
    color: #232b42;
}



@media only screen and (max-width: 1600px) {
    h1 {
        margin-bottom: 40px;
        font-size: 27px;
    }

    .three-col-grid-wrapper {
        padding: 70px 0px;
    }
}

@media only screen and (max-width: 1200px) {
    .three-col-grid {
        justify-content: center;
    }
}
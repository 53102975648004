.contact-wrapper {
    margin-top: 90px;
    margin-bottom: 90px;
}

.contact-wrapper h1 {
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 60px;
}

.contact-wrapper h3 {
    margin: 0px 0px 19px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #232b42;
    font-size: 18px;
}

.contact-wrapper h3:last-of-type {
    margin-top: 45px;
}

.contact-wrapper .item, .contact-wrapper .item a {
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 18px;
    text-decoration: none;
}

.contact-wrapper .item {
    margin-bottom: 10px;
    display: flex;
}

.contact-wrapper .item:last-of-type {
    margin-bottom: 0px;
}

.contact-wrapper .item span {
    width: 70px;
}

.contact-wrapper .left {
    display: inline-block;
    border-right: 1px solid #dfe2ec;
    padding-right: 120px;
    margin-right: 120px;
    vertical-align: top;
}

.contact-wrapper .field-wrapper {
    position: relative;
}

.contact-wrapper .field-wrapper input {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    height: 52px;
    width: 100%;
    padding: 0px 15px;
}

.contact-wrapper input, .contact-wrapper textarea {
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 17px;
}

.contact-wrapper textarea {
    padding: 15px;
}

.contact-wrapper input:focus, .contact-wrapper textarea:focus {
    outline: none;
}

.contact-wrapper .field-wrapper textarea {
    border-radius: 10px;
    border: 2px solid #dfe2ec;
    width: 100%;
    height: 115px;
    resize: vertical;
}

.contact-wrapper .two-col {
    display: flex;
    margin-bottom: 25px;
}

.contact-wrapper .two-col .field-wrapper {
    min-width: 307px;
    max-width: 307px;
}

.contact-wrapper .two-col .field-wrapper:first-of-type {
    margin-right: 35px;
}

.contact-wrapper .field-wrapper label {
    display: block;
    font-family: 'Open Sans', sans-serif;
    color: #232b42;
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 1;
}

.error-msg {
    color: #b90000;
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    left: 0px;
}

.contact-wrapper .error-msg {
    bottom: -25px;
}

.contact-wrapper .field-wrapper input.error-field {
    border-color: #b90000;
}

.send-btn {
    margin-top: 32px;
    position: relative;
}

.send-btn.disabled {
    background-color: rgb(87, 87, 87);
    color: rgb(207, 207, 207);
    cursor: default;
    opacity: 1;
}

.contact-wrapper .right {
    display: inline-block;
    position: relative;
}

.email-loader {
    height: 25px;
    position: absolute;
    right: 5px;
}

.sent-msg {
    position: absolute;
    left: 0;
    top: -80px;
    width: 100%;
    color: #fff;
    background-color: #b90000 ;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    padding: 15px;
}

.contact-info h3:last-of-type  {
    margin-top: 0px;
}

@media only screen and (max-width: 1600px) {
    .contact-wrapper {
        margin-top: 70px;
    }

    .contact-wrapper .item, .contact-wrapper .item a, .contact-wrapper .field-wrapper label  {
        font-size: 16px;
    }

    .contact-wrapper .item {
        margin-bottom: 5px;
    }

    .contact-wrapper h1 {
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 1200px) {
    .contact-wrapper .right {
        max-width: 500px;
    }

    .contact-wrapper .left {
        padding-right: 60px;
        margin-right: 60px;
    }

    .contact-wrapper .two-col .field-wrapper {
        width: 48%;
        min-width: auto;
    }
}

@media only screen and (max-width: 992px) {

    .contact-wrapper .left {
        padding-right: 0;
        margin-right: 0;
        max-width: 100%;
        border: none;
        display: flex;
    }

    .contact-wrapper  h3:last-of-type {
        margin-top: 0px;
    }
    .contact-wrapper .contact-info {
        margin-right: 50px;
    }
    .contact-wrapper .right {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .contact-content {
        display: flex;
        flex-direction: column-reverse;
    }

    .contact-wrapper .two-col .field-wrapper {
        max-width: 100%;
    }

    .contact-wrapper .working-hours {
        margin-left: 50px;
    }
    .contact-wrapper .working-hours .item {
        display: flex;

    }

    .contact-wrapper .items {
        columns: 2;
        column-gap: 40px;
    }

    .contact-wrapper .item span {
        width: 55px;
    }

    .sent-msg {
        top: -100px;
    }
}

@media only screen and (max-width: 768px) {
    .contact-wrapper .items {
        columns: 1;
    }
}

@media only screen and (max-width: 576px) {
    .contact-wrapper .left {
        display: block;
    }

    .contact-wrapper .working-hours {
        margin-left: 0;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 480px) {

    .contact-wrapper .two-col  {
        display: block;
    }

    .contact-wrapper .two-col .field-wrapper {
        width: 100%;
        margin-bottom: 25px;
    }

    .contact-wrapper .field-wrapper label {
        margin-bottom: 10px;
    }

    .contact-wrapper .error-msg {
        bottom: -22px;
    }
}
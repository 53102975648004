.navigation a {
    color: #232b42;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-decoration: none;
    font-size: 17px;
    position: relative;
}

.navigation a:not(:last-child) {
    margin-right: 30px;
}

.navigation a.active::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #232b42;
    left: 0px;
    bottom: -6px;
}

.nav-footer a.active::after {
    display: none;
}

.nav-footer a {
    color: #bfc8d7;
}

.nav-footer a:hover {
    color: #fff;
}

@media only screen and (max-width: 1600px) {
    .navigation a {
        font-size: 15px;
    }
}

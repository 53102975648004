.payment-wrapper {
  min-height: 70vh;
  padding: 90px 15px;
  width: 600px;
}

.options {
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 16px;
  flex-wrap: wrap;
  margin-bottom: 35px;
  column-count: 2;
}

.option-item {
  display: flex;
  margin-bottom: 10px;
}

.option-name {
  margin-left: 5px;
  font-size: 15px;
  transform: translateY(-1px);
}

.option-name span {
  font-weight: 700;
}

.arrow {
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: transparent;
  text-indent: -9999px;
  border-top: 2px solid #232b42;
  border-left: 2px solid #232b42;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: transparent;
  margin-left: 10px;
  top: 4px;
}

.arrow.down {
  transform: rotate(-135deg);
}

.arrow.rotate {
  transform: rotate(45deg);
  top: 8px;
}

.additional {
  margin-top: 15px;
}

.payment-wrapper h3 {
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 20px;
  font-weight: 700;
}

.payment-wrapper .select-wrapper label {
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}

select:focus {
  outline: none;
}

.payment-wrapper h1 {
  margin-bottom: 20px;
  text-align: left;
}

.payment-wrapper .rv-image {
  border-radius: 10px;
  height: 200px;
  width: 300px;
  object-fit: cover;
  margin-left: 50px;
}

.payment-wrapper .rv-image:hover {
  cursor: default;
}

.rv-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.total-price {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  color: #232b42;
}

.total-price span {
  font-weight: 700;
}

.item {
  font-family: "Open Sans", sans-serif;
  color: #232b42;
  font-size: 17px;
  margin-bottom: 5px;
}

.item span {
  font-weight: 700;
}

.payment-title {
  margin-top: 40px;
}

.payment-error {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  text-align: center;
  color: #b90000;
  margin: 10px auto 0px;
}

.success-wrapper {
  text-align: center;
  padding: 90px 0px;
}

.success-wrapper img {
  width: 100px;
}

.success-wrapper h1 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.success-text {
  font-family: 'Open Sans', sans-serif;
  color: #232b42;
  max-width: 60%;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.7;
}

.success-text a {
  color: #3286ed;
  font-weight: 700;
}

.success-wrapper {
  min-height: calc(100vh - 327px);
}

.addons-note {
  font-family: 'Open Sans', sans-serif;
  color: #232b42;
  font-style: italic;
  margin-bottom: 30px;
}

.addons-note a {
  font-weight: 700;
  color: #232b42;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .payment-wrapper {
    width: 100%;
    padding: 45px 15px;
  }

  .rv-info-wrapper {
    flex-direction: column;
  }

  .rv-info-wrapper .rv-image {
    margin-left: 0;
    margin-top: 15px;
  }

  .additional {
    margin-top: 0px;
  }

  .additional h3 {
    margin-top: 10px;
  }
}
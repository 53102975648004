.footer {
    background-color: #0a1934;
    position: relative;
    padding: 30px;
}

.footer-logo {
    width: 235px;
    margin-bottom: 20px;
}

.footer-content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-upper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.footer .right {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
}

.footer .right, .footer .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.phone {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
}

.copyright, .below-footer {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #404f69;
}

.below-footer {
    position: absolute;
    left: 46px;
    top: 47px;
    color: #fff;
}

.footer-socials {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer-socials img {
    height: 30px;
    width: 30px;
}

@media only screen and (max-width: 991px) {
    .footer-upper {
        flex-direction: column;
        justify-content: center;
    }

    .footer .left, .footer .right {
        height: auto;
        text-align: center;
    }

    .footer-socials {
        margin: 20px auto;
    }

    .footer {
        height: auto;
        padding: 30px;
    }

    .footer-content .nav-footer {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .copyright {
        text-align: center;
        margin: 40px auto 0px;
    }

    .footer-logo {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 576px) {
    .navigation a:not(:last-child) {
        margin-right: 18px;
    }

    .navigation a {
        font-size: 14px;
        line-height: 2;
    }

}

@media only screen and (max-width: 435px) {
    .navigation a:not(:last-child) {
        margin-right: 25px;
    }

    .navigation a {
        font-size: 15px;
    }
}